import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


import BestellungForm from "./Steps/BestellungForm";
import BezahlungForm from "./Steps/BezahlungForm"
import ZahlungsartForm from "./Steps/ZahlungsartForm";
import ZahlMethodenPayPal from "./Steps/Zahlart3"
import FertigForm from "./Steps/FertigForm"

import Page from "../material-ui-shell/lib/containers/Page" // 'material-ui-shell/lib/containers/Page'

import { useIntl } from 'react-intl'
import { validDefault } from './meta';
import { ICheckout } from "./interfaces";
import { korrFehlerAnzeigeAusschalten } from '../Helpers/FormValidator';


import { IsumObjSI, IDetails } from "../interfaces/serviceInvoice"
import { Person } from "../models-ts/person"
import { test } from "../interfaces/globals";
import axios from "axios";
import { useGlobalContext } from '../context/globalContext'
import { useNavigate } from 'react-router-dom'


export const toDo = {
    Intro: 0, BestellungPrüfen: 1, ZahlMethodenPayPal: 2, Bezahlung: 3, Postpay: 4,  Fertig: 5,    // Zahlungsart: 1, 

}

const bC = {  // Bezahlcode
    PayPal: 0, Rechnung: 1,
}


const optZahls = [
    {
        id: bC.PayPal,
        optLabel: "PayPal",
    },
    {
        id: bC.Rechnung,
        optLabel: "Rechnung",
    },

];



export type OptionType = {
    id: number | null;
    optLabel: string;

};

const initialRow = {
    id: 1,
    anlagedatum: new Date(),
    name: '',
    message: '',
    email: '',
    agbs: undefined,


}  // 


export interface IValidation {
    isValid: boolean;
    validation?: {}
    ngNr?: object;
    ngProzAnteilGrundkosten?: object;
    ngBez?: any;

}
const Test = test.kId;   // Komplex-ID

export interface IFormProps {
    mknr: number;
    retwPaypal?: { paypalsuccess: string; error: string; k: string; };
    startsWith?: number;
    
}

const CheckoutForm = (props: IFormProps) => {
    const { mknr, retwPaypal, startsWith } = props
    const {komplexNr } = useGlobalContext()
    let iKId = Test;   
    if (komplexNr !== undefined  && !null) 
       {iKId = komplexNr as number}
    if (mknr !== undefined && !null)      
       {iKId = mknr}    

    const intl = useIntl()
    const [row, setRow] = React.useState<ICheckout>(initialRow);
    const [details, setDetails] = React.useState<IDetails[]>([])
    const [verw, setVerw] = React.useState<Person>()

    let weiter = "Weiter"
    const initialState = {
        isLoading: false,
        isSubmitting: false,  // OP: noch nicht eingebunden - Quelle suchen...
        errorMessage: "",
        messageFromServer: "",
        updated: false,     // Textänderungen durchgeführt noch nicht gespeichert - NOcH unused..
        errorFlag: false,
    };
    //@ts-ignore
    global.ppp = null;  // Versuch

    const [data, setData] = React.useState(initialState);
    // const [activeStep, setActiveStep] = React.useState(0);
    const [activeStep, setActiveStep] = React.useState(startsWith !== undefined ? startsWith : 0);
    const [stepFailed, setStepFailed] = React.useState(-1)
    const [optZahl, setOptZahl] = React.useState<OptionType>(optZahls[0])
    const [validation, setValidation] = React.useState<IValidation>(validDefault.SetToValid());
    const [ppp, setPPP] = React.useState<Object>({})
    const navigate = useNavigate()
    React.useEffect(() => {
        axios.get("/api/createAndDownloadFile3", {
          params: {
            code: "R",
            mId: "",
            mKId: iKId,
          },
        })
          .then((response) => {
            console.log(response);
            const retw: IsumObjSI = response.data
            setVerw(retw.sumObjSi.verwalter)
            setDetails(retw.sumObjSi.invoice as IDetails[])
    
          }).catch(error => {
            setData({
              ...data,
              messageFromServer: error.response !== undefined ? error.response.data.message : null,
              errorMessage: error.message,
              isLoading: false,
              isSubmitting: false,
              errorFlag: true,
              updated: false,
            });
            console.log(error);
          });
    
        // OP: muss noch in Zweig abschlusszaehlererfassung verschoben werden....
    
      }, [])

    const onCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {

        //row["agbs"] = event.target.checked ? 1 : 0   -- geht nicht!!!!
        //setRow(row)
        setRow({ ...row, ["agbs"]: event.target.checked ? 1 : 0 })   // nur so!

        if (event.target.checked) {
            korrFehlerAnzeigeAusschalten(validation, "agbs")
        }
    }

    const onChange = (_e: any, value: string) => {
        const id = parseInt(value, 10);
        const option = optZahls.filter(o => o.id === id)[0];

        setOptZahl(option);
        console.log(option);
    }

    const handleNext = (e: { preventDefault: () => void; }) => {
        e.preventDefault();   // verhindere Defaultverhalten... Preventing the page from reloading??? - hier notwendig????? nein!?!
        // was ist das Standardverhalten bei diesem Button überhaupt, was verhindert werden soll?
        setData(initialState);  // notwendig ? überhaupt richtige Stelle?
        if (activeStep === toDo.BestellungPrüfen) {
            let validation = validDefault.validate(row);
            setValidation(validation);
            if (validation.isValid) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
        else {

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        //setActiveStep(0);
        navigate('/home')
    };
    const getSteps = () => {
        return [
            { label: "Intro - Bearbeitungshinweise:", description: "" },
            //{ label: "Zahlungsart auswählen", description: "Bitte zwischen PayPal oder Rechnung (*) wählen." },
            { label: "Bestellung prüfen", description: "Heizkostenabrechnung - kostenpflichtig bestellen" },
            { label: "Bezahlung durchführen", description: "Rechnung, Paypal" },
            { label: "Ergebnis kontrollieren", description: "von Rechnung oder Paypal" },
            { label: "Fertig", description: "" },]
    }
   

    const getStepContent = (step: number) => {
        switch (step) {
            case toDo.Intro:
                // return <BetriebskostenForm mknr={mknr} />
                return `PayPal ist bevorzugte Abrechnungsmethode. Bezahlung per Rechnung ist ggf. auch über PayPal möglich`;
            // case toDo.Zahlungsart:
            //    return <></>
                //return <ZahlungsartForm onChange={onChange} optZahls={optZahls} optZahl={optZahl} />
            case toDo.BestellungPrüfen:
                weiter = "Kostenplfichtig bestellen"
                return <BestellungForm details={details} verw={verw as Person}  optZahl={optZahl} mknr={mknr} onChange={onCheckBox} row={row} validation={validation} />
            case toDo.ZahlMethodenPayPal:
                 return <ZahlMethodenPayPal setPPP={setPPP} details={details} verw={verw as Person} />
            case toDo.Bezahlung:
                return <BezahlungForm ppp={ppp} optZahl={optZahl} mknr={mknr} />
            case toDo.Postpay:
                return <FertigForm retwPaypal={retwPaypal}   />
            case toDo.Fertig:
                    return `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`;
            default:
                return 'unbearbeiteter Step';
        }
    }
    const steps = getSteps()
    return (
        //@ts-ignore
        <Page pageTitle={intl.formatMessage({ id: 'checkout' })}>
            <Box sx={{ p: 2, minWidth: 400 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                optional={
                                    index === 12 ? (
                                        <Typography variant="caption">Zahlungsbedingungen wählen</Typography>
                                    ) : null}>
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                <div>

                                    <Typography>{step.description}</Typography>
                                    <Box sx={{ mb: 2 }}>{getStepContent(activeStep)}  </Box>
                                    <div>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}>
                                            Zurück
                                        </Button>
                                        <Button
                                            disabled={index === toDo.Bezahlung}
                                            variant="outlined"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}>
                                            { weiter}
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography></Typography>
                        <Button variant="outlined" onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                            Startseite
                        </Button>
                    </Paper>
                )}
            </Box>
            <br></br>
            <div>
                {data.messageFromServer && data.errorFlag && (
                    <span className="form-error">{data.messageFromServer}</span>
                )}
                {data.messageFromServer && !data.errorFlag && (
                    <span className="form-success">{data.messageFromServer}</span>
                )}
            </div>
            <br></br>
            {data.errorMessage && (
                <span className="form-error">{data.errorMessage}</span>
            )}
        </Page>
    );
}
export default CheckoutForm
