import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
//mport { preventTransitions } from "../../Helpers/preventTransition"


export interface IFormProps {

  retwPaypal?: { paypalsuccess: string; error: string; k: string; };

}

const FertigForm = (props: IFormProps) => {
  const { retwPaypal } = props
  const navigate = useNavigate()
  let ok = false, error = "", mKnr = 0
  if (retwPaypal !== undefined) {
    ok = (retwPaypal.paypalsuccess === "true")
    error = retwPaypal.error;
    mKnr = parseInt(retwPaypal.k)
  }


  let uuid = window.localStorage.getItem("uuidv1")

  // https://blog.8bitzen.com/posts/how-to-use-componentWillMount-with-react-hooks
  useEffect(() => {

    return function cleanup() {
      window.localStorage.removeItem("uuidv1");
    };

  }, [uuid])

  /*
  console.log(props.retwPaypal)
  
  const paypalSuccess = props.paypal.paypalsuccess
  const ok =  (paypalSuccess === "true" && props.paypal.uuid === uuid)
  const error = props.paypal.error;
 */

  const handleReset = () => {
    //setActiveStep(0);
    navigate('/home')
  };
  const handleClick = () => {
    //setActiveStep(0);
    navigate('/contact')
  };
  const displayOk = () => {
    return (
      <React.Fragment>

        <form className="container-fluid">

          <h1> Bezahlung erfolgreich durchgeführt. </h1>
          <br />
          <br />
          <h2>Vielen Dank für Ihre Vertrauen!</h2>
          <br />
          <h2>hier geht es zurück zu Startseite um den Druck erneut aufzurufen. </h2>
          <Button variant="outlined" onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Startseite
          </Button>
        </form>
      </React.Fragment>
    )
  }
  const displayNotOk = () => {
    const displayError = error === "abort" ? "Paypal ist durch Benutzer beendet worden" : "Objekt: " + mKnr + " Fehler: " + error

    return (
      <form className="container-fluid">
        <h1> Bestellung nicht erfolgreich durchgeführt. </h1>
        <br />
        <h2>
          {displayError}
        </h2>
        <Button variant="outlined" onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
          Startseite
        </Button>
        <br />
        oder
        <br />
        <Button variant="outlined" onClick={handleClick} sx={{ mt: 1, mr: 1 }}>
          Kontaktseite
        </Button>

      </form>
    )
  }

  return (
    <React.Fragment>

      {ok === true ? (displayOk()) :
        (displayNotOk())}
    </React.Fragment>
  )

}
export default FertigForm  