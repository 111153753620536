import React from "react";
import { ICheckout } from "./interfaces";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';

import { IEObj, convert, } from "../Helpers/converter";


interface IPropsDlg {
  row: ICheckout;
  name: string; // | any;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void
  ro?: boolean;  // readonly
  valid?: any;
  classname?: any;
  label?: any

}

const xx = () =>{
  return (
    <div>Test</div> 
  )
}

export const CustomCheckBox = ({ row, name, onChange, ro, valid, classname, label}: IPropsDlg) => {
 // const classes = classname();   // classname wäre nicht unbedingt notwendig..

  const errObj: IEObj = convert(name, valid)
  let readonly = false;
  if (ro !== undefined) {
    readonly = ro;
  }
  // console.log("Feld: " + name + " value: " + row[name].toString());
  return (
    <FormControl sx={{ m: 0 }} component="legend" variant="standard" error={errObj.hasError}>
      <FormGroup>
        <FormControlLabel
          label={label}
          control={<Checkbox
            disabled={readonly}
            name={name}
            checked={row[name] as boolean}
            onChange={onChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />}
        />
      </FormGroup>
      <FormHelperText>{errObj.errorText}</FormHelperText>
    </FormControl>
  );
};

