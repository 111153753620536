import React  from 'react'
import App from "./base-shell/lib/containers/App/App"  // 'base-shell/lib'
import _config from './config'
// import { CookieBanner } from "./components/react-cookie-law"
import AuthContextProvider from "./context/AuthContext";

import { MyGlobalContext } from "./context/globalContext"
import axios from "axios"
import {Company, CompanyAttributes } from "./models-ts/company";
/*
const Cookiebanner = () => {
  return (
    <CookieBanner
      message="Diese Website verwendet Cookies"
      wholeDomain={true}
      onAccept={() => { }}
      onAcceptPreferences={() => { }}
      onAcceptStatistics={() => { }}
      onAcceptMarketing={() => { }}
      privacyPolicyLinkText='Datenschutzerklärung'
      necessaryOptionText='Notwendig'
      preferencesOptionText='Präferenzen'
      statisticsOptionText='Statistiken'
      marketingOptionText='Marketing'
      acceptButtonText='Bestätigen'
      declineButtonText='Ablehnen'
      policyLink="/showdocument/Datenschutz IT-Vergabe.de"
    // showDeclineButton = {true}
    // dismissOnScroll = {true}
    />
  )
}
*/
export default function Heizung24() {
  //   
 
  const [uuidv1, setUuidv1] = React.useState("");
  const [checkout, setCheckout] = React.useState(false);
  const [komplexNr, setKomplexNr] = React.useState<number | undefined | null>(null)
  const [demoNr, setDemoNr] = React.useState<number | undefined | null>(520)
  const initialState = {
    isLoading: false,
    isSubmitting: false,  // OP: noch nicht eingebunden - Quelle suchen...
    errorMessage: "",
    messageFromServer: "",
    updated: false,     // Textänderungen durchgeführt noch nicht gespeichert - NOcH unused..
    errorFlag: false,
};

interface IDataObj {
  //  personadr: Personadresse;
  // person: Person;
  companie: Company;     // Server API:  findbyPK - gibt genau ein Element zurück...
  
}

const [data, setData] = React.useState(initialState);

  React.useEffect(() => {
    const Id = 1
    axios.get(`api/company/${Id}`)
    .then((response) => {
        // const retw: response.data
        setDemoNr(response.data.faDemoNumber)
        // console.log(retw.companie.faDemoNumber)    


    }).catch((error: { response: { data: { message: any; }; } | undefined; message: any; }) => {  // PseudoError z.b. status(400) - programmierer gesteuert...
        setData({
            ...data,
            messageFromServer: error.response !== undefined ? error.response.data.message : null,
            errorMessage: error.message,
            isLoading: false,
            isSubmitting: false,
            errorFlag: true,
            updated: false,
        });
        console.error(error);
    });
  },[])
  //const { appConfig } = useConfig()
  return (
    <MyGlobalContext.Provider value={{demoNr,setDemoNr, komplexNr, setKomplexNr }}>
      <AuthContextProvider>
        {/* <Cookiebanner /> */}       
        <App config={_config} />
      </AuthContextProvider>
      </MyGlobalContext.Provider>
  )


}
// App config={_config})