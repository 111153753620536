/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import CheckoutSite  from  "../sites/checkout";
import AuthorizedRoute from "../base-shell/lib/components/AuthorizedRoute" // 'base-shell/lib/components/AuthorizedRoute'
import UnauthorizedRoute from "../base-shell/lib/components/UnauthorizedRoute" //'base-shell/lib/components/UnauthorizedRoute'

import ResetPasswordPage from "../pages/ResetPasswordPage/ResetPasswordPage";
import ValidateEmailPage  from "../pages/ValidateEmailPage/ValidateEmailPage";
const CheckoutForm = lazy(() => import("../Checkout/CheckoutForm"));

const GebaeudeSelektionsForm =  lazy(() => import("../GebaeudeSelektion/Selektion"));
const ContactForm =  lazy(() => import('../pages/Contact/ContactForm'));


const SignIn = lazy(() => import('../pages/SignIn/SignIn'))

const SignUp = lazy(() => import('../pages/SignUp/SignUp'))
const PasswordReset = lazy(() => import('../pages/PasswordReset/PasswordReset'))
const About = lazy(() => import('../pages/About'))
//const Home = lazy(() => import('../pages/Home/Home'))
const ListPageDemo = lazy(() => import('../pages/ListPageDemo'))
const TabsDemo = lazy(() => import('../pages/TabsDemo'))
const MyAccount = lazy(() => import('../pages/MyAccount/MyAccount'))

const ShowDocumentPage = lazy(() => import("../sites/ShowDocument"))  
const ImpressumPage = lazy(() => import("../sites/Impressum"));
const AgbsPage = lazy(() => import("../sites/Agbs"));
const WiderrufPage = lazy(() => import("../sites/Widerruf"));


//   <ContactForm  redirectTo="/home"  />
const routes = [ 
  {  
    path: '/checkout',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <CheckoutForm />
      </UnauthorizedRoute>
    ),
  }, 
  {  // auslösende Serverroute vom Server selbst
    path: '/abschluss',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <CheckoutSite />
      </UnauthorizedRoute>
    ),
  }, 
  {
    path: '/home',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <GebaeudeSelektionsForm/>
      </UnauthorizedRoute>
    ),
  }, 
  {
    path: "/widerruf",   
    exact: true,
    element: (
      <UnauthorizedRoute>
      <WiderrufPage  redirectTo="/home"  />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/showagbs",   
    exact: true,
    element: (
      <UnauthorizedRoute>
      <AgbsPage  redirectTo="/home"  />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/impressum",   
    exact: true,
    element: (
      <UnauthorizedRoute>
      <ImpressumPage  redirectTo="/home"  />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/contact",   
    exact: true,
    element: (
      <UnauthorizedRoute>
      <ContactForm  redirectTo="/home"  />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/showdocument/:name",   
    exact: true,
    element: (
      <UnauthorizedRoute>
        <ShowDocumentPage  redirectTo="/home"  />
      </UnauthorizedRoute>
    ),
  },
  {
    path: '/validateEmail/:token',   
    exact: true,
    element: (
      <UnauthorizedRoute>
        <ValidateEmailPage  redirectTo="/home"  />
      </UnauthorizedRoute>
    ),
  },
  {
    path: '/resetPassword/:token',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <ResetPasswordPage  redirectTo="/home"  />
      </UnauthorizedRoute>
    ),
  }, 
  {
    path: '/signin',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <SignIn redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: '/signup',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <SignUp redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: '/password_reset',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <PasswordReset redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: '/about',
    exact: true,
    element: <About />,
  },
  {
    path: '/my_account',
    exact: true,
    element: (
      <AuthorizedRoute>
        <MyAccount />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/homeX',
    exact: true,
    element: (
      <AuthorizedRoute>
        <CheckoutSite mknr={0} />
      </AuthorizedRoute>
    ),
  },  
]

export default routes
