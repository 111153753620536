import FormValidator from '../Helpers/FormValidator';

const passwordMatch = (repeatPassword: string, data: { password: string; }) => {
    // debugger;
    return data.password === repeatPassword
}

export const validResetPassword = new FormValidator([
    {
      field: 'username',
      method: 'isEmpty',
      validWhen: false,
      message: 'E-mail wird benötigt.'
    },
    {
      field: 'username',
      method: 'isEmail',
      validWhen: true,
      message: 'Das ist keine gültige e-mail Adresse.'
    },

    {
      field: 'password',
      method: 'isEmpty',
      validWhen: false,
      message: 'Passwort wird gebraucht.'
    },
    {
      field: 'repeatPassword',
      method: 'isEmpty',
      validWhen: false,
      message: 'Passwortbestätigung wird benötigt.'
    },
    {
      field: 'repeatPassword',
      method: passwordMatch,   // notice that we are passing a custom function here
      validWhen: true,
      message: 'Passwort and Passwortbestätigung nicht identisch.'
    }
  ]);

export const validForgotPassword = new FormValidator([
    {
        field: 'username',
        method: 'isEmpty',
        validWhen: false,
        message: 'E-mail wird benötigt.'
    },
    {
        field: 'username',
        method: 'isEmail',
        validWhen: true,
        message: 'Das ist keine gülige E-mail Adresse.'
    },
    {
        field: 'password',
        method: 'isEmpty',
        validWhen: true,
        message: ''
    },
    {
        field: 'repeatPassword',
        method: 'isEmpty',
        validWhen: true,
        message: ''
    },

]);
export const validUsername = new FormValidator([
    {
        field: 'username',
        method: 'isEmail',
        validWhen: true,
        message: 'Das ist keine gülige E-mail Adresse.'
    },
    {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'Passworteingabe fehlt'
    },
    {
        field: 'repeatPassword',
        method: 'isEmpty',
        validWhen: true,
        message: ''
    },

]);

export const validRegisterUser = new FormValidator([

    {
        field: 'username',
        method: 'isEmpty',
        validWhen: false,
        message: 'E-mail Adresse wird benötigt.'
    },
    {
        field: 'username',
        method: 'isEmail',
        validWhen: true,
        message: 'Das ist keine gültige e-mail Adresse.'
    },

    {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'Passwort wird gebraucht.'
    },
    {
        field: 'repeatPassword',
        method: 'isEmpty',
        validWhen: false,
        message: 'Passwortbestätigung wird benötigt.'
    },
    {
        field: 'repeatPassword',
        method: passwordMatch,   // notice that we are passing a custom function here
        validWhen: true,
        message: 'Passwort and Passwortbestätigung nicht identisch.'
    }
]);
