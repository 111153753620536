import validator from 'validator';
// https://medium.com/code-monkey/client-side-form-validation-in-react-40e367de47ba
export const korrFehlerAnzeigeAusschalten = (validation: any, name: string): void => {
  if (!validation.isValid) {
    for (const [key, val] of Object.entries(validation)) {
      if (key === name) {
        // @ts-ignore
        val.message = ""
        break;
      }
    }
  }
}

class FormValidator {
  validations: any;

  constructor(validations: any) {
    // validations is an array of validation rules specific to a form
    this.validations = validations;

  }
  // OP: Typescript Deep Dive
  // OP: https://basarat.gitbook.io/typescript/type-system/index-signatures#declaring-an-index-signature
  validate(state: any) {
    // start out assuming valid
    let validation = this.SetToValid();

    // for each validation rule
    this.validations.forEach((rule: { field: string | number; args: never[]; method: string | number; validWhen: any; message: any; }) => {
      // if the field hasn't already been marked invalid by an earlier rule
      //@ts-ignore
      if (!validation[rule.field].isInvalid) {
        // determine the field value, the method to invoke and optional args from 
        // the rule definition
        // console.log(state[rule.field])
        // Kurzschlussoperatorn: https://www.math.kit.edu/ianm2/lehre/java2007w/seite/aktuelles/media/weitereausdruecke.pdf
        const field_value = state[rule.field] === null || state[rule.field] === undefined ? "" : state[rule.field].toString();  // geändert js 08.04.22 - nur null case - jetzt ohne undefined
        const args = rule.args || [];
        //@ts-ignore
        const validation_method = typeof rule.method === 'string' ? validator[rule.method] : rule.method

        // call the validation_method with the current field value as the first
        // argument, any additional arguments, and the whole state as a final
        // argument.  If the result doesn't match the rule.validWhen property,
        // then modify the validation object for the field and set the isValid
        // field to false
        if (validation_method(field_value, ...args, state) !== rule.validWhen) {
          //@ts-ignore
          validation[rule.field] = { isInvalid: true, message: rule.message }
          validation.isValid = false;
        }
      }
    });

    return validation;
  }

  SetToValid() {
    const validation = {}

    this.validations.map((rule: { field: string | number; }) => (
      //@ts-ignore
      validation[rule.field] = { isInvalid: false, message: '' }
    ));

    return { isValid: true, ...validation };
  }
}

export default FormValidator;

