import * as React from "react";
import axios from 'axios';
import jwt_decode from 'jwt-decode';
// Quellen: 
// https://levelup.gitconnected.com/learn-react-hooks-by-building-an-auth-based-to-do-app-c2d143928b0b
// Code: C:\React\_Context API\react-hooks-by-example-master


/** Custom types */
type UserAuth = {
  avatar: string;
  token: string;
  authenticated: boolean;
  id: string;
  email: string;
  username: string;
  roles: string;  // Plural!
  emailConfirmed?: number
};

// https://www.npmjs.com/package/axios
// Global axios defaults:
// axios.defaults.baseURL = 'https://api.example.com';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-u
// SAMPLE: axios.defaults.headers.common['Authorization'] = store.getState().auth.user.token;
// HTTP header names are case-insensitive, according to RFC 2616: 4.2: Each header field consists of a name followed by a colon (":") and the field value. Field names are case-insensitive.10.03.2011

export const setAuthToken = (token: string | null) => {
  if(token) {
      axios.defaults.headers.common['Authorization'] = token;  
  }
  else {
      delete axios.defaults.headers.common['Authorization'];
  }
}


/** Custom Hook: */

const useAuthHandler = (initialState: UserAuth) => {
  const [auth, setAuthJs] = React.useState(initialState);

  const setAuthStatus = (userAuth: UserAuth) => {
    setAuthToken(userAuth.token.replace("Bearer ", ""))
    window.localStorage.setItem("UserAuth", JSON.stringify(userAuth));
    setAuthJs(userAuth);
  };

  const setUnauthStatus = () => {
    setAuthToken(null);
    window.localStorage.removeItem("UserAuth");
    setAuthJs(DEFAULT_USER_AUTH);
  };

  return {
    auth,
    setAuthStatus,
    setUnauthStatus
  };
};

/** Utils */
const DEFAULT_USER_AUTH = {
  avatar: "",
  token: "", authenticated: false, id: "",
  email: "", username: "", roles: "", emailConfirmed: 0,
};


// https://nozzlegear.com/blog/implementing-a-jwt-auth-system-with-typescript-and-node
// https://github.com/auth0/jwt-decode
interface EncodeResult {
  token: string,
  exp: number,
  iat: number
}
/** Return user auth from local storage value */
const getStoredUserAuth = (): UserAuth => {
  // Geprüft - ok: 28.08.20
  // https://stackoverflow.com/questions/847185/convert-a-unix-timestamp-to-time-in-javascript
  // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date/now
  // JS timestamps are in milliseconds and PHP (Unix) delivers in second
  const currentTime = Date.now() / 1000; // decoded.exp are in sconds!!!, so you have to multiply 
  // wether decoded.exp with 1000 or divide currentTime through 1000...

  // window.localStorage.clear();  //  Wieder RAUS!!!!! - nur zwecks test 
  const auth = window.localStorage.getItem("UserAuth");
  if (auth) {
    if (auth === 'undefined' || auth === null) {
      setAuthToken(null);
      return DEFAULT_USER_AUTH;
    }
    const authObj = JSON.parse(auth);
    setAuthToken(authObj.token)

    const decoded: EncodeResult = jwt_decode(authObj.token);
    //console.log(new Date(decoded.exp), new Date(currentTime))
    if (decoded.exp < currentTime) {

      window.localStorage.removeItem("UserAuth");
      setAuthToken(null);

      return DEFAULT_USER_AUTH;
    }
    return JSON.parse(auth);
  }
  return DEFAULT_USER_AUTH;
};


interface IAuthContextInterface {
  auth: UserAuth;
  setAuthStatus: (userAuth: UserAuth) => void;
  setUnauthStatus: () => void;
}

export const authContext = React.createContext<IAuthContextInterface>({
  auth: DEFAULT_USER_AUTH,
  setAuthStatus: () => { },
  setUnauthStatus: () => { }
});

const { Provider } = authContext;

const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { auth, setAuthStatus, setUnauthStatus } = useAuthHandler(getStoredUserAuth());

  return (
    <Provider value={{ auth, setAuthStatus, setUnauthStatus }}>
      {children}
    </Provider>
  );
};


export default AuthContextProvider;
