import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../providers/Auth'
import { useConfig } from '../providers/Config'

import { authContext } from  "../../../context/AuthContext"

function UnauthorizedRoute({ children, redirectTo = '/', ...rest }) {
  const { appConfig } = useConfig()
  const { auth: authConfig } = appConfig || {}
  const { redirectTo: _redirectTo = redirectTo } = authConfig || {}
  //const { auth } = useAuth()
  const auth = React.useContext(authContext);
  if (!auth.authenticated) {
    return children
  } else {
    return <Navigate to={_redirectTo} replace />
  }
}

export default UnauthorizedRoute
