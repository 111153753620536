import React, { useContext } from 'react'
import { useTheme as useAppTheme } from '../../providers/Theme'
import MenuContext from '../../providers/Menu/Context'
import { useTheme } from '@mui/material/styles'
import { useConfig } from "../../../../base-shell/lib/providers/Config" //  'base-shell/lib/providers/Config'
import { useOnline } from '../../../../base-shell/lib/providers/Online'

import { useIntl } from 'react-intl'
import {
  AppBar,
  Toolbar,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material'
import { ChevronLeft, Menu as MenuIcon } from '@mui/icons-material'


import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,

  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));





export default function ({
  children,
  pageTitle,
  onBackClick,
  isLoading,
  appBarContent = null,
  contentStyle,
  tabs = null,
}) {
  const { isRTL } = useAppTheme()
  const isOnline = useOnline()
  const theme = useTheme()
  const { appConfig } = useConfig()
  const { menu } = appConfig || {}
  const { width = 240, appBarLeadingContent = null } = menu || {}

  const { toggleThis, isDesktop, isMenuOpen } = useContext(MenuContext)
  const intl = useIntl()
  const classes = useStyles();


  let headerTitle = ''

  if (typeof pageTitle === 'string' || pageTitle instanceof String) {
    headerTitle = pageTitle
  }

  const handleDrawerMenuClick = () => {
    if (!isMenuOpen) {
      toggleThis('isMiniMode', false)
      toggleThis('isMenuOpen', true)
      if (!isDesktop) {
        toggleThis('isMobileMenuOpen')
      }
    } else {
      toggleThis('isMobileMenuOpen')
    }
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <AppBar
        position={isDesktop ? 'absolute' : undefined}
        sx={{
          width:
            isMenuOpen && isDesktop ? `calc(100% - ${width}px)` : undefined,
          zIndex: theme.zIndex['drawer'],
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          maxHeight: 64,
          marginLeft: isRTL ? 0 : -12,
          marginRight: isRTL ? 30 : 0,
        }}
      >
        <Toolbar>
          {(isMenuOpen && isDesktop) ||
            (!onBackClick && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerMenuClick}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            ))}
          {/* james- check if this is dead code? */}
          {onBackClick && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onBackClick}
            >
              <ChevronLeft />
            </IconButton>
          )}
          {!onBackClick && isMenuOpen && false && (
            <div style={{ marginRight: 32 }} />
          )}
          {appBarLeadingContent}
          {/* js geändert 01.07.22 alt: h6 ---- james- check if this is dead code? */}
          <Typography variant="h5" color="inherit" noWrap>    
            {headerTitle}
          </Typography>
          <div style={{ flex: '1 1 auto' }} />
          {appBarContent}
        </Toolbar>
      </AppBar>
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'flex-end',
          //...theme.mixins.toolbar,
          minHeight: 64, //height of AppBar
        }}
      />

      {isLoading && <LinearProgress />}
      {!isOnline && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: 15,
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          <Typography variant="caption" color="textSecondary" noWrap>
            {intl.formatMessage({
              id: 'offline',
              defaultMessage: 'Offline',
            })}
          </Typography>
        </div>
      )}
      {tabs}
      <div style={{ flex: 1, overflow: 'auto', ...contentStyle }}>
        {children}
      </div>
    </div>
  )
}
/*
  <div style={{ flex: 1, overflow: 'auto', ...contentStyle }}>
        {children}
      </div>
*/