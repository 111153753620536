import React from 'react'
import { useAuth } from "../../base-shell/lib/providers/Auth" // 'base-shell/lib/providers/Auth'
import { useNavigate, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useMenu } from "../../material-ui-shell/lib/providers/Menu" // 'material-ui-shell/lib/providers/Menu'
import { useTheme } from '@mui/material/styles'
import axios from "axios";


import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import jwt_decode from 'jwt-decode';
import { authContext } from '../../context/AuthContext';

import { Link, } from "react-router-dom"; // Wichtig - Link geht über withRouter
import { useParams } from 'react-router-dom'
import { iDecoded } from "../interface"

const ValidateEmailPage = ({ redirectTo = '/' }) => {
  const auth = React.useContext(authContext);

  const initialState = {
    errorMessage: null,
    messageFromServer: null,
    updated: false,
    isLoading: true,
    error: false,
  };

  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  //@ts-ignore
  const { toggleThis } = useMenu()
   //@ts-ignore
  const { setAuth } = useAuth()
  const [data, setData] = React.useState(initialState);
  const params = useParams();

  React.useEffect(() => {
    console.log(params.token)
    setData({
      ...data,
      isLoading: true,
    });
    axios
      .get('/validateEmail', {
        params: {
          verifyEmailToken: params.token,
        },
      })
      .then(response => {
        console.log(response);
        if (response.data.success) {
          const decoded: iDecoded = jwt_decode(response.data.token); // Test
          // Anstelle von: localStorage.setItem('JWT', response.data.token);
          auth.setAuthStatus(
            {
              avatar: decoded.avatar,
              token: response.data.token.replace("Bearer ", ""),
              authenticated: true,
              id: decoded.id,
              email: decoded.email,
              username: decoded.name,
              roles: decoded.roles
            }
          );
          setData({
            ...data,
            messageFromServer: response.data.message,
            error: false,
            updated: true,
            isLoading: false,
          });
          authenticate({
            displayName: '', // email ist username
            email: decoded.email,
          })
        } else {
          setData({
            ...data,
            messageFromServer: response.data.message,
            updated: false,
            isLoading: false,
            error: true,
          });

        }
      })
      .catch(error => {
        console.log(error);
        setData({
          ...data,
          updated: false,
          isLoading: false,
          error: true,
          messageFromServer: error.message
        });
      });
    // https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.token]);

  const authenticate = (user: { displayName: string; email: string }) => {
    setAuth({ isAuthenticated: true, ...user })   // Doppelt: wird nirgens mehr abgefragt....
    toggleThis('isAuthMenuOpen', false)

    let from = new URLSearchParams(location.search).get('from')

    if (from) {
      navigate(from, { replace: true })
    } else {
      navigate(redirectTo, { replace: true })
    }
  }


  const errorOut = (messageFromServer: string | null) => {
    if (messageFromServer === "user not in database") { return "Nicht erfolgreich oder schon durchgeführt." }
    else {
      if (messageFromServer === "Verifikation out of time") {
        return "Verifikation per e-mail ist zeitlich ausgelaufen"
      }
      else
        return messageFromServer

    }
  }
  const WarningStyle = {
    color: 'red',
  };



  const renderHasError = () => {
    return (
      <div>
        <div style={WarningStyle}>
          {errorOut(data.messageFromServer)}
        </div>
        <br />
        <br />
        <Button component={Link} to="/contact" color="primary" variant="contained">
          Kontaktformular
        </Button>

        <br />
        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >


          <Link to="/signin">
            {intl.formatMessage({ id: 'sign_in' })}
          </Link>
        </div>
        <br />
        <br />
        <Link to="/" >Startseite</Link>
        <br />
        <br />
      </div>
    );
  }

  const renderIsLoading = () => {
    return (
      <React.Fragment>
        <div>Lade Benutzerdaten....</div>
        <CircularProgress />
      </React.Fragment>
    );
  }

  function renderUpdated() {
    return (
      <div>
        <p>
          Erfolgreich und angemeldet!
        </p>
        <Link to="/" >Startseite</Link>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div style={{ border: "2px solid #ccc", padding: 5, margin: 5, }}>
        <h4> E-mail Adresse gegenprüfen</h4>
        {
          data.isLoading ? renderIsLoading() : data.error ? renderHasError() : renderUpdated()
        }
      </div>
    </React.Fragment>)
}
export default ValidateEmailPage

