  import FormValidator from '../Helpers/FormValidator';

  export const validDefault = new FormValidator([
    // Aufstellung der möglichen Datentypen:
    // https://github.com/validatorjs/validator.js/tree/master/src/lib
    {
      field: 'agbs',
      method: 'isInt',    
      args: [{ gt: 0 }], 
      validWhen: true,
      message: 'Bitte AGB`s lesen und bestätigen.'
    },
  
  ]);

  // export {}