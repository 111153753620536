// https://dev.to/madv/usecontext-with-typescript-23ln 
import { createContext, useContext } from "react"
// Kasus Knacktus: Ist setKomplexNr automatisch der passende Setter zu komplexNr???
// https://dev.to/madv/usecontext-with-typescript-23ln
export type GlobalContent = {
  demoNr : number | undefined | null  
  komplexNr : number | undefined | null     
  uuidv1?: string
  checkout?: boolean
  setDemoNr:(nr: number | null) => void  
  setKomplexNr:(nr: number | null) => void  
  setUuidv1?:(nr: string) => void
  setCheckout?:(nr: boolean) => void
}
export const MyGlobalContext = createContext<GlobalContent>({
demoNr: undefined,
komplexNr: undefined,    
setDemoNr: () => {},
setKomplexNr: () => {},
uuidv1: "", 
setUuidv1: () => {},
checkout: false,
setCheckout: () => {},
})
export const useGlobalContext = () => useContext(MyGlobalContext)