import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

// Icons
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";

import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';



export const PaperComponent = (props: PaperProps) => {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }


// https://material-ui.com/customization/palette/#palette-colors

export const StyledTableCell = withStyles((theme: Theme) =>

    createStyles({
        head: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.common.white,

        },
        body: {
            fontSize: 18,
        }
    }),
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        }
    }),
)(TableRow);


export function IconsTableCellAdd(props: { onAddMode: any, add: boolean, classname: any }) {
    const { onAddMode, add, classname } = props;
    // const classes = useStyles();
    const classes = classname();
    return (
        <>
            <TableCell className={classes.iconTableCell}>
                <Tooltip title="Erfassung" arrow>
                    <IconButton aria-label="add" onClick={() => onAddMode()} disabled={add}>
                        <AddOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </>
    );
};
