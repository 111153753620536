import React, { useContext} from "react";
import CheckoutForm from "../Checkout/CheckoutForm";

import { useGlobalContext } from '../context/globalContext'

import queryString from "query-string"

import {toDo} from "../Checkout/CheckoutForm"
//const queryString = require('query-string');
const CheckoutSite = () => { // functional component written in ES6  
  // const appContext = useContext(AppContext);

  
  // let uuid = appContext.payIdGetter;  // geht nicht... warum?
  console.log(window.localStorage.getItem("uuidv1")); // 20.09.2020 -  geht!
  // let tada = appContext.tada;
  // console.log(new Date().toLocaleString() +  ":  uuid , tada = " + uuid)
  // OP: search mit uuid vergleichen


  /*
  React.useEffect(() => {
    console.log(appContext);
    return function cleanup() {
      appContext.updateCheckout(false);
    };

  }, [])
  */
  const search = window.location.search.toString();
  let queryKeys = queryString.parse(search)
  console.log(queryKeys)
  const mknr = parseInt(queryKeys.k)
  // console.log(appContext.data.products)
 // console.log(login);
  return (
    <React.Fragment>
     
        <CheckoutForm mknr={mknr} startsWith={toDo.Postpay} retwPaypal={queryKeys}  />
    </React.Fragment>
  )
};
export default CheckoutSite
