import { makeStyles } from '@material-ui/core/styles';


import { teal, grey } from "@material-ui/core/colors";



export const useStyles2 = makeStyles(theme => ({
	fab: {
	  margin: theme.spacing(2),
	},
	root: {
  
	  width: "100%",
	  marginTop: theme.spacing(3),
	  overflowX: "auto"
	},
	table: {
	  minWidth: 150
	},
	dateTableCell: {
	  minWidth: 20
	},
	iconTableCell: {
	  width: 50
	},
	numberTableCell: {
	  minWidth: 50
	},
	selectTableCell: {
	  width: 40,
  
	},
	selectTableCellError: {
	  width: 40,
	  color: theme.palette.error.main,
	},
	tableCell: {
	  width: 30,
	  height: 40
	},
	input:
	{
	  minWidth: 360,
	  height: 40
	},
	textField: {
	  marginLeft: theme.spacing(0),
	  marginRight: theme.spacing(0),
	  width: 150,
	},
  }));
   /* {
  "& .MuiFormLabel-root": {
  color: "black" // or red
  },*/



  export const useStyles = makeStyles(_theme => ({
	root: {
	  flexGrow: 1
	},
	primaryColor: {
	  color: teal[500]
	},
	secondaryColor: {
	  color: grey[700]
	},
  
	padding: {
	  padding: 0
	},
	mainHeader: {
	  backgroundColor: grey[100],
	  padding: 20,
	  alignItems: "center"
	},
	mainContent: {
	  padding: 20
	},
	secondaryContainer: {
	  padding: "1px 25px",
	  backgroundColor: grey[200]
	},
	input: {
	  width: 130,
	  height: 40
	},
  }));
  

const styles = {
	selectTableCellError: {
		width: '30px',		
		color: "#F42B4B",       
	},	
	empty:{}
}



export default styles
