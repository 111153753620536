import red from '@mui/material/colors/red'
import green from '@mui/material/colors/green'
import pink from '@mui/material/colors/pink'
import blue from '@mui/material/colors/red'
const themes = [
  {
    id: 'default',
    source: {
      palette: {
        primary: {
          main: '#556cd6',
        },
        secondary: {
          main: '#19857b',
        },
        error: {
          main: red.A400,
        },
        background: {
          default: '#fff',
        },
      },
    },
  },
  {
    id: 'red',
    color: red[500],
    source: {
      components: {   // js - unfertig.. klappt 
        MuiButton: {  // 
          variants: [
            {
              props: { variant: 'dashed' },
              style: {
                textTransform: 'none',
                border: `2px dashed ${red[500]}`,
              },
            },
            {
              props: { variant: 'dashed', color: 'secondary' },
              style: {
                border: `4px dashed ${red[500]}`,
              },
            },
          ],
        },
      },
      palette: {
        primary: red,
        secondary: pink,
        error: red,
        info:{  // js 07.07.22 - zwecks Fehlersuche...
          // light: will be calculated from palette.primary.main,
          main:  red[500],
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        }, 
        background: {
          default: '#fff',
        },      
      },
    },
  },
  {
    id: 'green',
    color: green[500],
    source: {
      palette: {
        primary: green,
        secondary: red,
        error: red,
        info:{  // js 07.07.22 - zwecks Fehlersuche...
          // light: will be calculated from palette.primary.main,
          main: green[500],
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },     
      },
    },
  },
]

export default themes
