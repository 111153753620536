import { Typography } from '@mui/material'
import Page from "../../material-ui-shell/lib/containers/Page" // 'material-ui-shell/lib/containers/Page'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import Paper from '@mui/material/Paper';

const bC = {  // Bezahlcode
  PayPal: 0, Rechnung: 1,
}

export type OptionType = {
  id: number | null;
  optLabel: string;

};

export interface IFormProps {
  mknr: number;
  optZahl: OptionType;
  ppp: Object;
}

const BezahlungForm = (props: IFormProps) => {
  const { mknr, optZahl, ppp } = props
  

  const intl = useIntl()
  const [isSdkReady, setIsSdkReady] = useState(false);


  React.useEffect(() => {
    if (optZahl.id === bC.PayPal) {
      if (!isSdkReady) {
        //@ts-ignore
        ppp.doCheckout()
        //@ts-ignore
        //global.ppp.doCheckout();
        setIsSdkReady(true)
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSdkReady])


  if (optZahl.id === 1) // Rechnung
  {
    return (
      //@ts-ignore
      //<Page pageTitle={intl.formatMessage({ id: 'bezahlung' })}>
      <Paper square elevation={0} sx={{ p: 3 }}>
        <Typography>Rechnung</Typography>

      </Paper>
      // </Page>
    )
  }
  else {
    return (
      //@ts-ignore
      //<Page pageTitle={intl.formatMessage({ id: 'bezahlung' })}>
      <Paper square elevation={0} sx={{ p: 3 }}>
        <Typography>Login von PayPal wird vorbereitet...</Typography>

      </Paper>
      // </Page>
    )
  }
}
export default BezahlungForm
