import { Typography } from '@mui/material'
import Page from "../../material-ui-shell/lib/containers/Page" //  "/material-ui-shell/lib/containers/Page'
import { useTheme } from '@mui/material/styles'
import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import CustomPaper from '../../components/CustomPaper'

import IconButton from '@mui/material/IconButton';
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from '@mui/material/Button';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive'
import { validResetPassword } from '../meta'
import axios from "axios";
import {IValidation} from "../interface"
import { Link } from 'react-router-dom'

export interface IFormProps {   // noch unused...
  match: { params: { token: unknown } } 
  redirectTo: string;
}

const ResetPasswordPage = ({ redirectTo = '/' }) => {
 //  console.log(props.match.params.token)
 const [showPass, setShowPass] = useState(false);
  const [showPassConf, setShowPassConf] = useState(false);
  const intl = useIntl()
  const theme = useTheme()
  const navigate = useNavigate() 
  const initialState = {
    username: "",
    userEmail: "",
    password: "",
    repeatPassword: "",
    isSubmitting: false,  // OP: noch nicht eingebunden - Quelle suchen...
    errorMessage: "",
    messageFromServer: "",
    updated: false,
    isLoading: true,
    error: false,
  };
  const [data, setData] = React.useState(initialState);  
  const [validation, setValidation] = React.useState<IValidation>(validResetPassword.SetToValid())
  const params = useParams();
 
  
  React.useEffect(() => {
    console.log(params)
    setData({
      ...data,
      isLoading: true,
    });
    axios
      .get('/resetPassword', {
        params: {
          resetPasswordToken: params.token,
        },
      })
      .then(response => {
        console.log(response);
        if (response.data.message === 'password reset link a-ok') {
          setData({
            ...data,
            isSubmitting: false,
            isLoading:false,
            username: response.data.userName,
          });
        } else {
          setData({
            ...data,
            messageFromServer: response.data,
            updated: false,
            isLoading: false,
            error: true,
          });

        }
      })
      .catch(error => {
        console.log(error.data);
      });
// https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.token]);



  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    const lvalidation = validResetPassword.validate(data)

    setValidation(lvalidation)  // globalisieren...
    if (lvalidation.isValid) {

      console.log(validation);
      setData({
        ...data,
        isSubmitting: true,
        errorMessage: "",
        messageFromServer: "",
      });
      axios
        .put('/updateForgottenPassword', {
          username: data.username,
          password: data.password,
        })
        .then(response => {
          console.log(response.data);
          if (response.data === "user not in database") {
            setData({
              ...data,
              messageFromServer: "Benutzer ist nicht in unserer Datenbank",
              updated: false,     
              isLoading: false,
              error: true,
            });
          }
          if (response.data.message === 'password updated') {
            setData({
              ...data,
              messageFromServer: "Passwort erfolgreich aktualisiert",
              updated: true,    
              isLoading: false,
              error: false,
            });
            // navigate('/signin', { replace: true })
          }
        })
        .catch(error => {
          setData({
            ...data,
            messageFromServer: error.message,         
            isLoading: false,
            error: true,
          });
          console.log(error);
        });
    }
  }
 

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [event.target.name]: event.target.value
      // Wichtig: Input name="repeatPassword" muss state Variable data.repeatPassword genau entsprechen!!!!  ID im Input field notwendig?
    });
  };
   // console.log(props.match.params.token)
  return (
    //@ts-ignore
    <Page
      pageTitle={intl.formatMessage({
        id: 'reset_password',
        defaultMessage: 'Reset Password',
      })}
      onBackClick={() => {
        navigate(-1)
      }}
    >
      <CustomPaper elevation={6}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: `100%`,
          }}
        >
          <Typography component="h1" variant="h5">
            {intl.formatMessage({
              id: 'reset_password',
              defaultMessage: 'Reset Password',
            })}
          </Typography>
          <form
            style={{ marginTop: theme.spacing(1) }}
            onSubmit={handleSubmit}
            noValidate
          >
             <TextField
              value={data.username}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={intl.formatMessage({
                id: 'email',
                defaultMessage: 'E-Mail',
              })}
              name="username"
              autoComplete="email"
            />
            <TextField
              value={data.password}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={intl.formatMessage({
                id: 'password',
                defaultMessage: 'Password',
              })}
              type={showPass ? "text" : "password"}
              id="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility"
                      onClick={() => setShowPass(!showPass)}>
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              autoComplete="current-password"
            />
            <TextField
              value={data.repeatPassword}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="repeatPassword"
              label={intl.formatMessage({
                id: 'password_confirm',
                defaultMessage: 'Confirm Password',
              })}
              type={showPassConf ? "text" : "password"}
              id="password_confirm"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">

                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassConf(!showPassConf)}>
                      {showPassConf ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ margin: theme.spacing(3, 0, 2) }}
            >
              {intl.formatMessage({
                id: 'reset_password',
                defaultMessage: 'Reset Password',
              })}
            </Button>
            <br></br>
            <br />
            {validation.username !== null && validation.username.isInvalid && (
              <span className="form-error">{validation.username.message}</span>
            )}
            {data.errorMessage && (
              <span className="form-error">{data.errorMessage}</span>
            )}
            {data.messageFromServer && (
              <span className="form-success">{data.messageFromServer}</span>
            )}
            <ReactIsCapsLockActive>
              {(active: any) => <span className="form-warning">{active ? 'Shift-Taste ist gedrückt' : ''}</span>}
            </ReactIsCapsLockActive>
            <br></br>
          </form>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
           
            
           <Link to="/signin">
              {intl.formatMessage({ id: 'sign_in' })}
            </Link>
          </div>       
        </div>
      </CustomPaper>
    </Page>
  )
}

export default ResetPasswordPage