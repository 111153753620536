import { Typography } from '@mui/material'
import Page from "../../material-ui-shell/lib/containers/Page" // 'material-ui-shell/lib/containers/Page'
import React from 'react'
import { useIntl } from 'react-intl'

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import axios from "axios";
import { CustomCheckBox } from "../StyledDlgElements"

import { useStyles, useStyles2 } from '../styles';

import { ICheckout } from "../interfaces";
import { test } from "../../interfaces/globals";
import { confToNumberTextString, confToMoneyTextString } from "../../Helpers/converter"
import { IsumObjSI, IDetails } from "../../interfaces/serviceInvoice"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Person } from "../../models-ts/person"
import { StyledTableCell, StyledTableRow } from "../../elements/StyledTableElements"

import { Link } from 'react-router-dom'

const Test = test.kId;



export type OptionType = {
  id: number | null;
  optLabel: string;

};

interface IFormProps {
  onChange(event: React.ChangeEvent<HTMLInputElement>): void

  mknr: number
  row: ICheckout
  validation?: any;
  optZahl: OptionType
  verw: Person,
  details: IDetails[]
}
const BestellungForm = (props: IFormProps) => {
  const { mknr, onChange, row, validation, optZahl, verw, details } = props
  const intl = useIntl()
  const classes = useStyles();
  const iKId = mknr !== undefined ? mknr : Test;

  //const [details, setDetails] = React.useState<IDetails[]>([])
  //const [verw, setVerw] = React.useState<Person>()
  const initialState = {

    done: false,
    isLoading: false,
    isSubmitting: false,  // OP: noch nicht eingebunden - Quelle suchen...
    errorMessage: "",
    messageFromServer: "",
    updated: false,     // Textänderungen durchgeführt noch nicht gespeichert - NOcH unused..
    errorFlag: false,
  };
  const [data, setData] = React.useState(initialState);
  /*
    React.useEffect(() => {
      axios.get("/api/createAndDownloadFile3", {
        params: {
          code: "R",
          mId: "",
          mKId: iKId,
        },
      })
        .then((response) => {
          console.log(response);
          const retw: IsumObjSI = response.data
          setVerw(retw.sumObjSi.verwalter)
          setDetails(retw.sumObjSi.invoice as IDetails[])
  
        }).catch(error => {
          setData({
            ...data,
            messageFromServer: error.response !== undefined ? error.response.data.message : null,
            errorMessage: error.message,
            isLoading: false,
            isSubmitting: false,
            errorFlag: true,
            updated: false,
          });
          console.log(error);
        });
  
      // OP: muss noch in Zweig abschlusszaehlererfassung verschoben werden....
  
    }, [])
    */
  console.log(details)
  const xx = () => {
    return (
      <div>
        Bitte beachten Sie für Ihre Bestellung auch unsere Widerrufsbelehrung.
      </div>
    )
  }
  // GSK: Each child in a list should have a unique "key" prop.
  // React.Children.toArray:  https://www.amitmerchant.com/auto-assigning-unique-key-to-each-child-of-list-in-react/
  return (

    <Paper square elevation={0} sx={{ p: 3 }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} size="small" aria-label="spanning table">
          <caption>...</caption>

          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={7}>
                <Typography variant="h6" gutterBottom component="div">
                  Rechnungsempfänger (Verwalter)
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  >
                {verw?.pAnredeAnrede.bez1}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  >
                {verw?.pVorname + " " + verw?.pNachname}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  >
                {verw?.pAdr.strasse}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  >
                {verw?.pAdr.postleitzahl + " " + verw?.pAdr.ort}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

          </TableBody>
        </Table>
        <Table sx={{ minWidth: 200 }} size="small" aria-label="spanning table">
          <caption>...</caption>

          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={7}>
                <Typography variant="h6" gutterBottom component="div">
                  Bestellung
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right">
                Pos.
              </TableCell>
              <TableCell>
                Leistung
              </TableCell>
              <TableCell align="right">
                Anzahl
              </TableCell>
              <TableCell align="right">
                Einzelpreis
                Brutto (EUR)
              </TableCell>
              <TableCell align="right" >
                Summe Netto (EUR)
              </TableCell>
              <TableCell align="right" >
                Mwst (%)
              </TableCell>
              <TableCell align="right" >
                Summe Brutto (EUR)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {React.Children.toArray(
              details.map((row) => (
                <StyledTableRow key={row.pos}>
                  <TableCell align="right"  >
                    {confToNumberTextString(row.pos as number)}
                  </TableCell>
                  <TableCell sx={{ maxWidth: 100 }}  >
                    {row.description}
                  </TableCell>
                  <TableCell align="right"  >
                    {confToNumberTextString(row.menge as number)}
                  </TableCell>
                  <TableCell align="right" >
                    {confToMoneyTextString(row.price as number)}
                  </TableCell>
                  <TableCell align="right" >
                    {confToMoneyTextString(row.sumNetto as number)}
                  </TableCell>
                  <TableCell align="right"  >
                    {confToNumberTextString(row.przMwst as number)}
                  </TableCell>
                  <StyledTableCell align="right" >
                    {confToMoneyTextString(row.brutto as number)}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )
            }
          </TableBody>
        </Table>
        <Table sx={{ minWidth: 200 }} size="small" aria-label="spanning table">

          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={7}>
                <Typography variant="caption" gutterBottom component="div">
                  Zahlungsart:
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  >
                {optZahl.optLabel}
              </TableCell>
            </TableRow>

          </TableHead>
          <TableBody>

          </TableBody>
        </Table>
      </TableContainer>

      <Stack spacing={1} style={{ border: "2px solid #ccc", padding: 10, marginTop: 50 }}>


        <Typography style={{ color: 'grey' }} variant="subtitle1" color="textSecondary">
          <Link target="_blank" rel="noopener noreferrer" to='/showagbs' >
            Allgemeine Geschäftsbedingungen (AGB)
          </Link>
        </Typography>

        <div>
          Bitte beachten Sie für Ihre Bestellung auch unsere
          <Typography style={{ color: 'grey' }} variant="subtitle2" color="textSecondary">
            <Link target="_blank" rel="noopener noreferrer" to='/widerruf' >
              Widerrufsbelehrung
            </Link>
          </Typography>
        </div>
        <CustomCheckBox {...{ row, name: "agbs", onChange, valid: validation, label: "Ich habe die AGB und die Widerrufsbelehrung Ihres Shop gelesen und bin mit deren Geltung einverstanden" }} />

      </Stack>

      <Grid container direction="row" className={classes.mainContent} spacing={1}>
        <div>
          {data.messageFromServer && data.errorFlag && (
            <span className="form-error">{data.messageFromServer}</span>
          )}
          {data.messageFromServer && !data.errorFlag && (
            <span className="form-success">{data.messageFromServer}</span>
          )}
        </div>
        <br></br>
        {data.errorMessage && (
          <span className="form-error">{data.errorMessage}</span>
        )}
      </Grid>
    </Paper>
  )


}
export default BestellungForm
/*
 <CustomCheckBox {...{ row, name: "agbs", onChange, valid: validation, label: "Ich habe die AGB und die Widerrufsbelehrung Ihres Shop gelesen und bin mit deren Geltung einverstanden" }} />


            <TableCell align="right"  >
              {sumObjSi.verwalter.pAdr.strasse}
            </TableCell>
            <TableCell sx={{ maxWidth: 100 }}  >
              {sumObjSi.verwalter.pAdr.postleitzahl}
            </TableCell>
            <TableCell align="right"  >
              {sumObjSi.verwalter.pAdr.ort}
            </TableCell>
*/