
/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
//export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
export function groupBy(list: any[], keyGetter: (arg0: any) => any) {
    const map = new Map();
    list.forEach((item: any) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
// https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects?page=2&tab=votes#tab-top
export const groupByES6 = (items: any[], key: string | number) => items.reduce(
    (result: { [x: string]: any; }, item: { [x: string]: string | number; }) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }), 
    {},
  );



const formatMoney = new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2  });

export const confToMoneyTextString = (value: number | bigint) => {
    if (value === null || value === undefined) // 
    {
        return "";
    }
    return formatMoney.format(value);
}

const formatMenge = new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 4  });

export const confToNumberTextString = (value: number | bigint) => {
    if (value === null || value === undefined) // 
    {
        return "";
    }

    return formatMenge.format(value);
}



export const conftoDateTextString = (value: string | number | Date) => {
    const text =  null;
    if (value !== null)   // Neu: 12.07.21: value null - 01.01.1970 abgefangen
    {
        const datum = new Date(value);
        const text = datum.toLocaleDateString("de-DE", {
            day: '2-digit', month: '2-digit', year: '2-digit'
        });
        return text;
    }
    return text;
}

// https://linguinecode.com/post/define-return-type-function-typescript
export interface IEObj {
    hasError: boolean;
    errorText: string;

}

export const convert = (name: string, valid: any): IEObj => {
    let eObj = {
        hasError: false,
        errorText: "",
    }
    if (valid === undefined) {
        return eObj
    }
    // OP: Internet Explorer Check Polyfill 


    for (const [key, val] of Object.entries(valid)) {
        if (key === name) {
            //console.log(key);
            //console.log(val)
            // @ts-ignore
            let test = val.isInvalid;
            eObj = {
                // @ts-ignore
                hasError: val.isInvalid,
                // @ts-ignore
                errorText: val.message,
            }
            return eObj;
            // hasError = value.isInvalid;
            // errorText = value.message;
        }
    }

    return eObj;
}

// https://stackoverflow.com/questions/33036487/one-liner-to-flatten-nested-object
/**
* Flatten a multidimensional object
*
* For example:
*   flattenObject({ a: 1, b: { c: 2 } })
* Returns:
*   { a: 1, c: 2}
*/
export const flattenObject = (obj: { [x: string]: any; }) => {
    const flattened = {}
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(flattened, flattenObject(obj[key]))
        } else {
            //@ts-ignore
            flattened[key] = obj[key]
        }
    })
    return flattened
}

export const flattArrWithObjects = (arr: any) => {
    let newArr = arr.map((obj: { [x: string]: any; }) => {
        return flattenObject(obj);;
    });
    return newArr
}

/*
 retw.mietvertrags.forEach((row) => {
          test.push(Object.assign(flattenObject(row)))   // auf die Serverseite bringen...
        });
*/